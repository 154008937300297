export default {
  baseStyle: {
    p: 4,
    letterSpacing: '.5px',
    borderRadius: '10px',
  },
  sizes: {
    md: {
      minW: '6rem',
      h: 'auto',
      p: 3,
    },
    lg: {
      minW: '10rem',
      h: 'auto',
    },
  },
  variants: {
    solid: {
      bg: '#2871d1',
      _hover: { bg: '#1e62b9' },
      _active: { bg: '#1e62b9' },
    },
    transparent: {
      border: '1px solid #fff',
      bg: 'transparent',
      _hover: { color: 'brand.600', bg: '#fff' },
      _active: { color: 'brand.600', bg: '#fff' },
    },
  },
  defaultProps: {
    size: 'lg',
    variant: 'solid',
  },
};
