import Button from './components/Button';
import Form from './components/Form';
import FormLabel from './components/FormLabel';
import Link from './components/Link';

export default {
  components: {
    Button,
    Form,
    FormLabel,
    Link,
  },
};
