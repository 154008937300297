import { AppProps } from 'next/app';
import Head from 'next/head';
import { ChakraProvider } from '@chakra-ui/react';

import theme from '@definitions/chakra/theme';
import 'swiper/css';
import '@styles/global.css';

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  return (
    <>
      <Head>
        <title>Valearnis - Tutoring | Grade improvement | Homeschooling</title>
        <meta
          name="description"
          content="Valearnis is a revolutionary educational platform for Australian secondary school students. We provide a tutoring alternative, grade improvement, and even homeschooling support in a modern, student-centric ecosystem. Come learn with us!"
        />
      </Head>
      <ChakraProvider theme={theme}>
        <Component {...pageProps} />
      </ChakraProvider>
    </>
  );
}

export default MyApp;
