import { ThemeOverride } from '@chakra-ui/react';

type GlobalStyles = Pick<ThemeOverride, 'styles'>;

export default {
  styles: {
    global: {
      'h1, h2, h3, h4, h5, h6': {
        fontWeight: 600,
        letterSpacing: '0.5px',
      },
      h1: {
        marginBottom: ['0.5rem', '0.75rem', '1rem'],
        letterSpacing: '2.4px',
      },
      h2: {
        fontSize: ['1.875rem', null, '2rem', '2.25rem'],
        letterSpacing: '2px',
      },
      h3: {
        fontSize: ['lg', null, 'xl'],
        marginBottom: '0.5rem',
      },
      p: {
        fontSize: ['md', null, 'lg'],
        marginBottom: '1rem',
        letterSpacing: '0.5px',
      },
      a: {
        _hover: {
          textDecoration: 'none',
        },
      },
      /* Disable focus shadow on non-keyboard interactions */
      ':focus:not(:focus-visible):not([role="dialog"]):not([role="menu"])': {
        boxShadow: 'none !important',
      },
    },
  },
} as GlobalStyles;
