import { mode } from '@chakra-ui/theme-tools';

type Dict = Record<string, any>;

const parts = ['requiredIndicator', 'helperText'];

function baseStyleRequiredIndicator(props: Dict) {
  return {
    marginStart: 1,
    color: mode('white', 'grey.600')(props),
  };
}

const baseStyle = (props: Dict): Dict => ({
  requiredIndicator: baseStyleRequiredIndicator(props),
});

export default {
  parts,
  baseStyle,
};
