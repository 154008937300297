export default {
  baseStyle: {
    my: 4,
    fontWeight: '600',
    letterSpacing: '.5px',
  },
  defaultProps: {
    colorScheme: 'blue',
    variant: 'unstyled',
  },
};
