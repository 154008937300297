export default {
  brand: {
    800: '#1a365d',
    700: '#153e75',
    600: '#243D9D',
    400: '#2a69ac',
  },
  header: {
    100: '#20232a',
  },
  main: {
    100: '#282c34',
  },
};
